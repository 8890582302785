// src/QandA.jsx

import React, { useState } from 'react';
import './q&aboxStyle.css'; // Make sure to create this CSS file for styling

const QandA = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [feedback, setFeedback] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission

    const response = await fetch('https://getform.io/f/bejykqra', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, message }), // Send email and message
    });

    if (response.ok) {
      setFeedback('Your message has been sent successfully!'); // Success feedback
      setEmail(''); // Clear email field
      setMessage(''); // Clear message field
    } else {
      setFeedback('There was an error sending your message. Please try again later.'); // Error feedback
    }
  };

  return (
    <div className="q-and-a-box">
      <h2>Have a Question?</h2>
      <form onSubmit={handleSubmit} className="q-and-a-form">
        <input
          type="email"
          placeholder="Your Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <textarea
          placeholder="Your Question"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          rows="4"
          required
        />
        <button type="submit">Send Question</button>
      </form>
      {feedback && <p className="feedback">{feedback}</p>}
    </div>
  );
};

export default QandA;
