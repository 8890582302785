// src/Footer.js

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './footerStyle.css'; // Ensure the correct path
import axios from 'axios';
import ContactForm from "./../EmailForm/EmailForm";
import QandA from '../Q&ABox/Q&ABox';

const Footer = () => {
  

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-logo">
          <h1>Your Logo</h1>
        </div>
        <nav className="footer-nav">
          <h3>Explore</h3>
          <ul>
            <li><Link to="/about">About Us</Link></li>
            <li><Link to="/contact">Contact</Link></li>
            <li><Link to="/privacy">Privacy Policy</Link></li>
            <li><Link to="/news">News</Link></li>
            <li><Link to="/blogs">Blogs</Link></li>
          </ul>
        </nav>
        <div className="footer-social">
          <h3>Connect with Us</h3>
          <div className="social-icons">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="social-icon">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="social-icon">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="social-icon">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="social-icon">
              <i className="fab fa-linkedin-in"></i>
            </a>
            <a href="https://youtube.com" target="_blank" rel="noopener noreferrer" className="social-icon">
              <i className="fab fa-youtube"></i>
            </a>
          </div>
        </div>
      </div>

      <div className="footer-newsletter">
        {/* <ContactForm></ContactForm> */}
        <QandA></QandA>
      </div>

      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Your Company. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
