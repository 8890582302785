// src/NewsItem.jsx
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import './NewsItem.css';



const NewsItem = ({ index, article }) => {


  return (
    <div className="news-item_nl" key={article.id} style={{ '--index': index }}>
      <img src={article.photo} alt={article.title} className="news-item-img_nl" />
      <div className="news-item-content_nl">
        <h2 className="news-item-title_nl">{article.title}</h2>
        <p className="news-item-date_nl">{article.date}</p>
        <p className="news-item-summary_nl">{article.summary}</p>
        <Link to={`/news/${article.id}`} className="news-item-readmore_nl">Read More</Link>
      </div>
    </div>
  );
};

export default NewsItem;
