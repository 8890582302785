import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Translation resources
const resources = {
  en: {
    translation: {
      welcome: "Welcome to our website",
      Privacy: "Privacy Policy",
      Contact: "Contact Us",
      Home: "Home",
      About:"About us",
      News:"News",
      Blogs:"Blogs",
      // Add more translations here...
    }
  },
  Pr: {
    translation: {
      welcome: "به سایت ما خوش آمدید",
      Privacy: "قوانین و مقررات",
      Contact: "تماس با ما",
      Home:"خانه",
      About:"درباره ما",
      News:"اخبار ها",
      Blogs:"بلاگ ها",
      // Add more translations here...
    }
  },
  Arb: {
    translation: {
      welcome: "به سایت ما خوش آمدید",
      Privacy: "قوانین و مقررات",
      Contact: "تماس با ما",
      Home:"خانه",
      About:"درباره ما",
      News:"اخبار ها",
      Blogs:"بلاگ ها",
      // Add more translations here...
    }
  },
  Che: {
    translation: {
      welcome: "به سایت ما خوش آمدید",
      Privacy: "قوانین و مقررات",
      Contact: "تماس با ما",
      Home:"خانه",
      About:"درباره ما",
      News:"اخبار ها",
      Blogs:"بلاگ ها",
      // Add more translations here...
    }
  },
  // Add more languages as needed...
};

// Initialize i18next
i18n
  .use(initReactI18next) // Pass i18n instance to react-i18next.
  .init({
    resources,
    lng: 'pr', // Default language
    fallbackLng: 'pr', // Use English if translation for current language is not available
    interpolation: {
      escapeValue: false, // React already safeguards from XSS
    },
  });

export default i18n;
