// src/EmailLoginForm.jsx
import React, { useState } from 'react';
import axios from 'axios';
import './EmailLoginForm.css';

const EmailLoginForm = () => {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [feedback, setFeedback] = useState('');
  const [step, setStep] = useState(1); // 1 for email input, 2 for code input
  const [sentCode, setSentCode] = useState(null);

  
  const handleEmailSubmit = async (e) => {
    e.preventDefault();

    

    try {
      const response = await axios.post('http://localhost:5000/send-code', { email });
      if (response.status === 200) {
        setFeedback('Email is allowed!');
        setStep(2); // Move to the next step
      }
    } catch (error) {
      setFeedback(error.response?.data || 'Email is not allowed!');
    }
  };

  const handleCodeSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:5000/check-code', { code });
      if (response.status === 200) {
        setFeedback('Verification successful! Navigating...');
        // Navigate to the next page
        localStorage.setItem('isLoggedIn', true);
        // Replace the below line with your navigation logic
        setTimeout(() => {
          window.location.href = '/admin'; // or use history.push('/next-page') if using react-router
        }, 2000);
      }
    } catch (error) {
      setFeedback(error.response?.data || 'Invalid code. Please try again.');
    }

  };

  return (
    <div className="email-login-form">
      <h2>Email Verification</h2>
      {step === 1 && (
        <form onSubmit={handleEmailSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="email-input"
            />
          </div>
          <button type="submit" className="submit-btn">Send Code</button>
        </form>
      )}
      {step === 2 && (
        <form onSubmit={handleCodeSubmit}>
          <div className="form-group">
            <label htmlFor="code">Verification Code:</label>
            <input
              type="text"
              id="code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              required
              className="code-input"
            />
          </div>
          <button type="submit" className="submit-btn">Verify Code</button>
        </form>
      )}
      {feedback && <p className="feedback">{feedback}</p>}
    </div>
  );
};

export default EmailLoginForm;
