import Choose from "../../Choose";
import "./navbarStyle.css";

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from "../../data/i18n";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLanguageOpen, setIsLanguageOpen] = useState(false); // State for language dropdown
  const [selectedLanguage, setSelectedLanguage] = useState('English'); // State for selected language
  const { t } = useTranslation(); // Get the translation function

  // Load language from localStorage on initial render
  useEffect(() => {
    const savedLanguage = 'En' || localStorage.getItem('language');
    if (savedLanguage) {
      setSelectedLanguage(savedLanguage);
    }
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleLanguageMenu = () => {
    setIsLanguageOpen(!isLanguageOpen);
  };

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    i18n.changeLanguage(language);
    localStorage.setItem('language', language); // Save selected language to localStorage
    setIsLanguageOpen(false); // Close dropdown after selection
  };

  return (
    <>
      <nav className="navbar">
        <div className="logo">MyLogo</div>
        <ul className="navbar-links">
          <li>
            <Link to="/">{t('Home')}</Link>
          </li>
          <li>
            <Link to="/about">{t('About')}</Link>
          </li>
          <li>
            <Link to="/privacy">{t('Privacy')}</Link>
          </li>
          <li>
            <Link to="/contact">{t('Contact')}</Link>
          </li>
          <li>
            <Link to="/news" onClick={toggleMenu}>{t('News')}</Link>
          </li>
          <li>
            <Link to="/blogs" onClick={toggleMenu}>{t('Blogs')}</Link>
          </li>
        </ul>
        <div className="language-container">
          <button className="language-toggle" onClick={toggleLanguageMenu}>
            {selectedLanguage}
          </button>
          {isLanguageOpen && (
            <ul className="language-dropdown">
              <li onClick={() => handleLanguageChange('En')}>English</li>
              <li onClick={() => handleLanguageChange('Pr')}>Persian</li>
              <li onClick={() => handleLanguageChange('Arb')}>Arabic</li>
              <li onClick={() => handleLanguageChange('Che')}>chinese</li>
            </ul>
          )}
        </div>
        <div className="menu-toggle" onClick={toggleMenu}>
          <div className={`bar ${isOpen ? 'open' : ''}`}></div>
          <div className={`bar ${isOpen ? 'open' : ''}`}></div>
          <div className={`bar ${isOpen ? 'open' : ''}`}></div>
        </div>
      </nav>

      {/* Sidebar */}
      <div className={`sidebar-menu ${isOpen ? 'open' : ''}`}>
        <ul className="sidebar-links">
          <li>
            <Link to="/" onClick={toggleMenu}>Home</Link>
          </li>
          <li>
            <Link to="/about" onClick={toggleMenu}>About</Link>
          </li>
          <li>
            <Link to="/privacy" onClick={toggleMenu}>Privacy Policy</Link>
          </li>
          <li>
            <Link to="/contact" onClick={toggleMenu}>Contact</Link>
          </li>
          <li>
            <Link to="/news" onClick={toggleMenu}>News</Link>
          </li>
          <li>
            <Link to="/blogs" onClick={toggleMenu}>Blogs</Link>
          </li>
        </ul>
      </div>

      {isOpen && <div className="overlay" onClick={toggleMenu}></div>}
    </>
  );
};

export default Navbar;
