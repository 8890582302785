// src/LatestBlogs.js

import React,{useEffect,useState} from 'react';
import './lastestblogsStyle.css';
import db from '../../data/db';
import { Link } from 'react-router-dom';

const LatestBlogs = () => {
  const [blogs, setblogs] = useState([]);
  useEffect(() => {
    const fetchNews = async () => {
      const allNews = await db.blogs.toArray();  // Fetch all news from IndexedDB
      setblogs(allNews);
    };
    fetchNews();
  }, []);
  return (
    <div className="latest-blogs-container">
      <h2 className="latest-blogs-title">Latest Blogs</h2>
      <div className="latest-blogs-grid">
        {blogs.slice(blogs.length - 3, blogs.length).map((blog) => (
          <div className="blog-card" key={blog.id}>
            <img src={blog.image} alt={blog.title} className="blog-image" />
            <div className="blog-content">
              <h3 className="blog-title">{blog.title}</h3>
              <p className="blog-description">{blog.description}</p>
              <Link to={`/blogs/${blog.id}`} className="read-more">Read More</Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LatestBlogs;
