import React from "react";
import "./homepageStyle.css";
import { useState, useEffect } from "react";
import Choose from "../../Choose";
import SlideShow from "../SlideShow/SlideShow";
import LatestNews from "../LastestNews/LastestNews";
import LogoDisplay from "../LogoDisplay/LogoDisplay";
import LatestBlogs from "../LastestBlogs/LastestBlogs";

const HomePage = ()=> {
    return(<>
    <SlideShow></SlideShow>
    <LatestNews></LatestNews>
    <LogoDisplay></LogoDisplay>
    <LatestBlogs></LatestBlogs>
    </>);
}
export default HomePage;