import React, { useState, useEffect } from 'react';
import './slideshowStyle.css'; // Import CSS for styling
import logo from './../Navbar/logo.svg';

const SlideShow = () => {
    const images = [
        logo,
        'https://via.placeholder.com/1920x500/33FF57/FFFFFF?text=Slide+2',
        logo,
        'https://via.placeholder.com/1920x500/FFFF33/FFFFFF?text=Slide+4',
    ];

    const [currentSlide, setCurrentSlide] = useState(0);

    const nextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
    };

    const prevSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide - 1 + images.length) % images.length);
    };

    useEffect(() => {
        const interval = setInterval(nextSlide, 5000); // Change slide every 5 seconds
        return () => clearInterval(interval); // Clear interval on unmount
    }, []);

    return (<>
        <div className="slideshow-container">
            <div className="slideshow">
                <button className="prev" onClick={prevSlide}>❮</button>
                <img src={images[currentSlide]} alt={`Slide ${currentSlide + 1}`} className="slide-image" />
                <button className="next" onClick={nextSlide}>❯</button>
            </div>
            <div className="slide-indicators">
                {images.map((_, index) => (
                    <span
                        key={index}
                        className={`indicator ${index === currentSlide ? 'active' : ''}`}
                        onClick={() => setCurrentSlide(index)}
                    />
                ))}
            </div>
        </div>

    </>

    );
};

export default SlideShow;
