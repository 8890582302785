// src/NewsList.jsx
import React,{useEffect,useState} from 'react';
import { Link } from 'react-router-dom';
import newsArticles from '../../data/News';
import './NewsList.css';
import NewsItem from '../NewsItem/NewsItem';
import db from '../../data/db';

const NewsList = () => {
  const [newsArticles, setNewsList] = useState([]);
  useEffect(() => {
    const fetchNews = async () => {
      const allNews = await db.news.toArray();  // Fetch all news from IndexedDB
      setNewsList(allNews);
    };
    fetchNews();
  }, []);
  return (
    <div className="news-list-container_nl">
      <h1 className="news-list-title_nl">Latest News</h1>
      {newsArticles.map((article, index) => (
        <NewsItem key={article.id} article={article} index={index}/>
      ))}
    </div>
  );
};

export default NewsList;
