// src/AboutUs.jsx

import React from 'react';
import './AboutUs.css'; // Ensure you have this CSS file for styling
import SubCompanies from '../SubCompanies/subcompanies'; // Import the SubCompanies component
import LeaderShipBox from '../LeadershipBox/LeaderShipBox';



const AboutUs = () => {
  return (
    <div className="about-us">
      <header className="about-header">
        <h1>About Us</h1>
        <p>Welcome to <strong>[Your Company Name]</strong>!</p>
      </header>

      <section className="mission">
        <h2>Our Mission</h2>
        <p>To deliver exceptional value and foster relationships with our clients.</p>
      </section>

      <section className="history">
        <h2>Our History</h2>
        <p>Founded in [Year], we’ve grown into a leading provider of [services], committed to excellence.</p>
      </section>

      <section className="president-message">
        <h2>Message from the President</h2>
        <p>"Thank you for being a part of our journey. Your support fuels our passion for innovation and service." - [President's Name]</p>
      </section>

      <section className="milestones">
        <h2>Milestones</h2>
        <ul>
          <li><strong>2010:</strong> Founded in [City], [Country].</li>
          <li><strong>2015:</strong> Reached [number] clients.</li>
          <li><strong>2020:</strong> Expanded to [new market or country].</li>
          <li><strong>2023:</strong> Launched [new product or service].</li>
        </ul>
      </section>

      <section className="testimonials">
        <h2>What Our Clients Say</h2>
        <blockquote>
          "Working with [Your Company Name] has been a game-changer for us!" - <strong>Client Name</strong>
        </blockquote>
        <blockquote>
          "The team was professional and delivered outstanding results." - <strong>Another Client Name</strong>
        </blockquote>
      </section>

      <LeaderShipBox></LeaderShipBox>

      <SubCompanies ></SubCompanies> {/* Include the SubCompanies component */}

      <section className="community-involvement">
        <h2>Community Involvement</h2>
        <p>We believe in giving back to the community. Our team actively participates in various initiatives including [describe initiatives, e.g., charity events, volunteer work].</p>
      </section>

      <section className="sustainability">
        <h2>Our Commitment to Sustainability</h2>
        <p>[Your Company Name] is dedicated to minimizing its environmental impact by [describe efforts, e.g., using renewable energy, reducing waste, etc.].</p>
      </section>

      <section className="press-coverage">
        <h2>Press Coverage</h2>
        <p>Featured in:</p>
        <ul>
          <li><a href="link-to-article-1" target="_blank" rel="noopener noreferrer">Article Title 1</a></li>
          <li><a href="link-to-article-2" target="_blank" rel="noopener noreferrer">Article Title 2</a></li>
        </ul>
      </section>

      <section className="awards">
        <h2>Awards & Certifications</h2>
        <ul>
          <li>Award Name - Year</li>
          <li>Certification Name</li>
        </ul>
      </section>

      <section className="faq">
        <h2>Frequently Asked Questions</h2>
        <div className="faq-item">
          <h3>What services do you offer?</h3>
          <p>We offer a range of services including [list services].</p>
        </div>
        <div className="faq-item">
          <h3>How can I contact you?</h3>
          <p>You can reach us via email at <a href="mailto:info@yourcompany.com">info@yourcompany.com</a>.</p>
        </div>
      </section>

      <section className="contact-info">
        <h2>Contact Us</h2>
        <p>Email: <a href="mailto:info@yourcompany.com">info@yourcompany.com</a></p>
      </section>
    </div>
  );
};

export default AboutUs;
