import React from "react";
import "./eventStyle.css";
import { useState, useEffect } from "react";
import Choose from "../../Choose";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import home from '../HomePage/HomePage';
import aboutUs from '../AboutUs/AboutUs';
import ContactUs from "../ContactUs/ContactUs";
import NewsList from "../News/NewsList";
import QandA from "../Q&ABox/Q&ABox";
import NewsItem from "../NewsItem/NewsItem";
import BlogList from "../BlogList/BlogList";
import BlogDetail from "../BlogDetail/BlogDetail";
import NewsDetail from "../NewsDetail/NewsDetail";
import PrivacyAndPolicy from "../Privacy&Policy/PrivacyandPolicy";
import EmailLoginForm from "../CMS Login/EmailLoginForm";
import Admin from "../Admin/Admin";
const Event = () => {
    const navigation = useNavigate();
    const location = useLocation();
    const pages = [
        "/login/admin",
        "/privacy",
        "/blogs/:id",
        "/news/:id",
        "/blogs",
        "/news",
        "/contact",
        "/about",
        "/home",
        "/",
    ];
    const notAllowedPages = [
        // "/admin"
    ]
    useEffect(() => {
        if (
            document.location.pathname.toLowerCase() !== document.location.pathname
        ) {
            document.location.pathname.replace(
                document.location.pathname.toLowerCase()
            );
        }
        for (let index = 0; index < notAllowedPages.length; index++) {
            const element = notAllowedPages[index];
            if (document.location.pathname.toLowerCase().startsWith(element)) {
                navigation("/home");
            }
        }
        for (let index = 0; index < pages.length; index++) {
            const element = pages[index];
            if (document.location.pathname.toLowerCase().startsWith(element)) {
                return;
            }
        }
        navigation("/home");
    }, []);
    return (<>
        <switch>
            <div id="event" className="event-size event-style">
                <Routes>
                    <Route path="/admin" Component={Admin} />
                    <Route path="/login/admin" Component={EmailLoginForm} />
                    <Route path="/privacy" Component={PrivacyAndPolicy} />
                    <Route path="/blogs/:id" Component={BlogDetail} />
                    <Route path="/news/:id" Component={NewsDetail} />
                    <Route path="/blogs" Component={BlogList} />
                    <Route path="/news" Component={NewsList} />
                    <Route path="/contact" Component={ContactUs} />
                    <Route path="/about" Component={aboutUs} />
                    <Route path="/home" Component={home} />
                    <Route path="/" element={<Navigate to="/home" />} />
                </Routes>
            </div>
        </switch>
    </>);
}
export default Event;
