import React from "react";
import "./homeStyle.css";
import { useState, useEffect } from "react";
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer'
import Event from '../Event/Event';
import InProgress from '../Errors/InProgress';
import Choose from "../../Choose";
const Home = () => {
  const [language, setLanguage] = useState("FA");
  const choosing = new Choose();
  window.addEventListener("resize", () => {
    document.querySelector("body").style.height = window.innerHeight + "px";
    document.querySelector("#root").style.height = window.innerHeight + "px";
  });
  useEffect(() => {
    document.querySelector("body").style.height = window.innerHeight + "px";
    document.querySelector("#root").style.height = window.innerHeight + "px";
  }, [window.innerHeight]);
  const handelError = (errorType) => {
    if (!document.getElementById("errors")) {
      return;
    }
    document.getElementById("errors").style.zIndex = "25";
    document.getElementById("errors").style.position = "absolute";
    document.getElementById("errors").children[
      errorType
    ].style.transitionDuration = "0.4s";
    document.getElementById("errors").children[errorType].style.display =
      "block";
    setTimeout(() => {
      document.getElementById("errors").children[errorType].style.display =
        "none";
    }, 3000);
  }
  return (
    <>
      <div id="errors">
        <InProgress title={"در حال توسعه"} />
      </div>
      <Navbar language={language} />
      <Event language={language}/>
      <Footer language={language}/>
    </>
  );
};

export default Home;
