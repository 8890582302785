import React, { Component } from "react";
import Home from "./components/Home/Home";
import './data/i18n';
const App = () => {
  return (
    <>
      <Home />
    </>
  );
};

export default App;