// src/NewsDetail.jsx
import React,{useEffect,useState} from 'react';
import { useParams, Link } from 'react-router-dom';
import db from '../../data/db';
import './NewsDetail.css';

const NewsDetail = () => {
  const [newsArticles, setNewsList] = useState([]);
  useEffect(() => {
    const fetchNews = async () => {
      const allNews = await db.news.toArray();  // Fetch all news from IndexedDB
      setNewsList(allNews);
    };
    fetchNews();
  }, []);
  const { id } = useParams(); // Extract the ID from the URL
  const article = newsArticles.find((news) => news.id === parseInt(id));

  if (!article) {
    return <div className="news-item-not-found_ni">News article not found.</div>;
  }

  return (
    <div className="news-item-container_ni">
      <div className="news-item-card_ni">
        <img src={article.photo} alt={article.title} className="news-item-image_ni" />
        <div className="news-item-content_ni">
          <h1 className="news-item-title_ni">{article.title}</h1>
          <p className="news-item-date_ni">{article.date}</p>
          <p className="news-item-summary_ni">{article.summary}</p>
          <p className="news-item-text_ni">{article.text}</p>
          <Link to="/news" className="news-item-back_ni">← Back to News List</Link>
        </div>
      </div>
    </div>
  );
};

export default NewsDetail;
