// src/EmailBox.jsx
import React, { useState } from 'react';
import './EmailBox.css'; // Make sure you have this CSS for styling

const EmailBox = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [feedback, setFeedback] = useState('');
    const [loading, setLoading] = useState(false); // For a loading state during submission

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission
    
        const response = await fetch('https://getform.io/f/bejykqra', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, message , name }), // Send email and message
        });
    
        if (response.ok) {
          setFeedback('Your message has been sent successfully!'); // Success feedback
          setEmail(''); // Clear email field
          setMessage(''); // Clear message field
        } else {
          setFeedback('There was an error sending your message. Please try again later.'); // Error feedback
        }
      };

    return (
        <section className="contact-form-section">
            <h2>Send Us a Message</h2>
            <form onSubmit={handleSubmit} className="contact-form">
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                        type="text"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="message">Message</label>
                    <textarea
                        id="message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                        rows="4"
                    ></textarea>
                </div>
                <button type="submit" className="submit-button" disabled={loading} onClick={handleSubmit}>
                    {loading ? 'Sending...' : 'Send Message'}
                </button>
                {feedback && <p className="feedback">{feedback}</p>}
            </form>
        </section>
    );
};

export default EmailBox;
