// src/BlogList.jsx
import React , {useState , useEffect} from 'react';
import BlogItem from '../BlogsItem/BlogItem'; // Ensure to create the BlogItem component
import './BlogList.css'; // Optional: Add your CSS for styling
import db from '../../data/db';

const BlogList = () => {
  const [blogs, setblogs] = useState([]);
  useEffect(() => {
    const fetchNews = async () => {
      const allNews = await db.blogs.toArray();  // Fetch all news from IndexedDB
      setblogs(allNews);
    };
    fetchNews();
  }, []);

  return (
    <div className="blog-list-container_bl">
      <h2 className="blog-list-title_bl">Our Blogs</h2>
      <div className="blog-list_ni">
        {blogs.map((blog) => (
          <BlogItem key={blog.id} blog={blog} />
        ))}
      </div>
    </div>
  );
};

export default BlogList;
