// src/ContactUs.jsx

import React, { useState } from 'react';
import { FaInstagram, FaWhatsapp, FaTelegram } from 'react-icons/fa'; // Importing icons
import './ContactUs.css'; // For styling
import EmailBox from '../EmailBox/EmailBox';

const ContactUs = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [feedback, setFeedback] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    setFeedback('Your message has been sent!');
    setName('');
    setEmail('');
    setMessage('');
  };

  return (
    <div className="contact-us-page">
      {/* Company Address and Map */}
      <section className="company-info">
        <h2>Our Office</h2>
        <p>Visit us at:</p>
        <p><strong>123 Main Street, City, Country</strong></p>
        <p>Phone: <a href="tel:+123456789">+123456789</a></p>
        <p>Email: <a href="mailto:info@company.com">info@company.com</a></p>

        {/* Embed Google Maps */}
        <div className="map-container">
          <iframe
            title="Company Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3162.8521045425225!2d-122.08385128415782!3d37.3860519798315!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fb0e43e391c53%3A0x4b0e9a7bf4bf4fbd!2sGoogleplex!5e0!3m2!1sen!2s!4v1634644881334!5m2!1sen!2s"
            width="600"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </section>

      <EmailBox></EmailBox>

      {/* Social Media Links */}
      <section className="social-media-section">
        <h2>Connect with Us</h2>
        <div className="social-icons">
          <a href="https://instagram.com/yourcompany" target="_blank" rel="noopener noreferrer">
            <FaInstagram size={40} color="#C13584" />
          </a>
          <a href="https://wa.me/123456789" target="_blank" rel="noopener noreferrer">
            <FaWhatsapp size={40} color="#25D366" />
          </a>
          <a href="https://t.me/yourcompany" target="_blank" rel="noopener noreferrer">
            <FaTelegram size={40} color="#0088cc" />
          </a>
          {/* Add more social platforms as needed */}
        </div>
      </section>

      {/* Contact Information */}
      <section className="contact-info-section">
        <h2>Contact Information</h2>
        <p>Phone: <a href="tel:+123456789">+123456789</a></p>
        <p>Email: <a href="mailto:info@company.com">info@company.com</a></p>
        <p>WhatsApp: <a href="https://wa.me/123456789" target="_blank" rel="noopener noreferrer">Chat with us</a></p>
        <p>Telegram: <a href="https://t.me/yourcompany" target="_blank" rel="noopener noreferrer">@yourcompany</a></p>
      </section>
    </div>
  );
};

export default ContactUs;
