// src/Admin.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Admin.css';
import db from '../../data/db';


const Admin = () => {
    const navigate = useNavigate();
    const [newsItem, setNewsItem] = useState({
        title: '',
        date: '',
        summary: '',
        photo: null, // Change to null for file upload
        text: '',
    });
    const [blogItem, setBlogItem] = useState({
        title: '',
        description: '',
        image: null, // Change to null for file upload
        text: '',
    });

    // Check if user is logged in
    useEffect(() => {
        const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
        if (!isLoggedIn) {
            navigate('/'); // Redirect to home page if not logged in
        }
    }, [navigate]);

    // Handle news item input changes
    const handleNewsChange = (e) => {
        const { name, value } = e.target;
        setNewsItem((prev) => ({ ...prev, [name]: value }));
    };

    // Handle blog item input changes
    const handleBlogChange = (e) => {
        const { name, value } = e.target;
        setBlogItem((prev) => ({ ...prev, [name]: value }));
    };

    // Handle file selection for news
    const handleNewsFileChange = (e) => {
        setNewsItem((prev) => ({ ...prev, photo: e.target.files[0] }));
    };

    // Handle file selection for blog
    const handleBlogFileChange = (e) => {
        setBlogItem((prev) => ({ ...prev, image: e.target.files[0] }));
    };

    // Helper function to convert a file to Base64
    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    // Handle news submission
    const handleNewsSubmit = async (e) => {
        e.preventDefault();

        // Convert the photo to Base64 before saving
        const base64Photo = await convertToBase64(newsItem.photo);
        const newNewsItem = { ...newsItem, photo: base64Photo };

        await db.news.add(newNewsItem);  // Store the news item in IndexedDB
        alert('News item added successfully!');
        setNewsItem({ title: '', date: '', summary: '', photo: null, text: '' });
    };

    // Handle blog submission
    const handleBlogSubmit = async(e) => {
        e.preventDefault();
        
        // Convert the photo to Base64 before saving
        console.log(blogItem.image);
        const base64Photo = await convertToBase64(blogItem.image);
        const newBlogItem = { ...blogItem, image: base64Photo };

        await db.blogs.add(newBlogItem);  // Store the news item in IndexedDB
        alert('News item added successfully!');
        setBlogItem({  title: '', description: '', image: null, text: '' });
    };

    return (
        <div className="admin-container">
            <h1>Admin Dashboard</h1>

            <div className="admin-form">
                <h2>Add News Item</h2>
                <form onSubmit={handleNewsSubmit}>
                    <input
                        type="text"
                        name="title"
                        placeholder="Title"
                        value={newsItem.title}
                        onChange={handleNewsChange}
                        required
                    />
                    <input
                        type="date"
                        name="date"
                        placeholder="Date"
                        value={newsItem.date}
                        onChange={handleNewsChange}
                        required
                    />
                    <textarea
                        name="summary"
                        placeholder="Summary"
                        value={newsItem.summary}
                        onChange={handleNewsChange}
                        required
                    />
                    <input
                        type="file"
                        name="photo"
                        accept="image/*"
                        onChange={handleNewsFileChange}
                        required
                    />
                    <textarea
                        name="text"
                        placeholder="Text"
                        value={newsItem.text}
                        onChange={handleNewsChange}
                        required
                    />
                    <button type="submit">Add News</button>
                </form>
            </div>

            <div className="admin-form">
                <h2>Add Blog Item</h2>
                <form onSubmit={handleBlogSubmit}>
                    <input
                        type="text"
                        name="title"
                        placeholder="Title"
                        value={blogItem.title}
                        onChange={handleBlogChange}
                        required
                    />
                    <textarea
                        name="description"
                        placeholder="Description"
                        value={blogItem.description}
                        onChange={handleBlogChange}
                        required
                    />
                    <input
                        type="file"
                        name="image"
                        accept="image/*"
                        onChange={handleBlogFileChange}
                        required
                    />
                    <textarea
                        name="text"
                        placeholder="Text"
                        value={blogItem.text}
                        onChange={handleBlogChange}
                        required
                    />
                    <button type="submit">Add Blog</button>
                </form>
            </div>
        </div>
    );
};

export default Admin;
