// src/SubCompanies.jsx

import React from 'react';
import './subcompanies.css'; // Create this CSS file for styling

const subCompanies = [
    {
        name: 'Subcompany 1',
        logo: 'link-to-logo-1.png',
        description: 'Subcompany 1 is dedicated to providing exceptional services in [field or service].',
        link: 'https://www.subcompany1.com', // Replace with actual link
    },
    {
        name: 'Subcompany 2',
        logo: 'link-to-logo-2.png',
        description: 'Subcompany 2 specializes in [field or service] with a focus on quality.',
        link: 'https://www.subcompany2.com', // Replace with actual link
    },
    {
        name: 'Subcompany 3',
        logo: 'link-to-logo-3.png',
        description: 'Subcompany 3 offers innovative solutions for [field or service].',
        link: 'https://www.subcompany3.com', // Replace with actual link
    },
    {
        name: 'Subcompany 4',
        logo: 'link-to-logo-4.png',
        description: 'Subcompany 4 is known for its expertise in [field or service].',
        link: 'https://www.subcompany4.com', // Replace with actual link
    },
    {
        name: 'Subcompany 5',
        logo: 'link-to-logo-5.png',
        description: 'Subcompany 5 delivers top-notch services in [field or service].',
        link: 'https://www.subcompany5.com', // Replace with actual link
    },
    {
        name: 'Subcompany 6',
        logo: 'link-to-logo-6.png',
        description: 'Subcompany 6 focuses on innovation and customer satisfaction in [field or service].',
        link: 'https://www.subcompany6.com', // Replace with actual link
    },
    {
        name: 'Subcompany 7',
        logo: 'link-to-logo-7.png',
        description: 'Subcompany 7 is a leader in providing [field or service].',
        link: 'https://www.subcompany7.com', // Replace with actual link
    },
    {
        name: 'Subcompany 8',
        logo: 'link-to-logo-8.png',
        description: 'Subcompany 8 is committed to excellence in [field or service].',
        link: 'https://www.subcompany8.com', // Replace with actual link
    },
    {
        name: 'Subcompany 9',
        logo: 'link-to-logo-9.png',
        description: 'Subcompany 9 is dedicated to enhancing customer experience in [field or service].',
        link: 'https://www.subcompany9.com', // Replace with actual link
    },
    {
        name: 'Subcompany 10',
        logo: 'link-to-logo-10.png',
        description: 'Subcompany 10 offers comprehensive solutions in [field or service].',
        link: 'https://www.subcompany10.com', // Replace with actual link
    },
    {
        name: 'Subcompany 11',
        logo: 'link-to-logo-11.png',
        description: 'Subcompany 11 is renowned for its innovative approaches to [field or service].',
        link: 'https://www.subcompany11.com', // Replace with actual link
    },
    {
        name: 'Subcompany 12',
        logo: 'link-to-logo-12.png',
        description: 'Subcompany 12 provides exceptional services focused on [field or service].',
        link: 'https://www.subcompany12.com', // Replace with actual link
    },
    {
        name: 'Subcompany 13',
        logo: 'link-to-logo-13.png',
        description: 'Subcompany 13 specializes in [field or service] with a customer-first approach.',
        link: 'https://www.subcompany13.com', // Replace with actual link
    },
    {
        name: 'Subcompany 14',
        logo: 'link-to-logo-14.png',
        description: 'Subcompany 14 delivers innovative solutions in [field or service].',
        link: 'https://www.subcompany14.com', // Replace with actual link
    },
    {
        name: 'Subcompany 15',
        logo: 'link-to-logo-15.png',
        description: 'Subcompany 15 focuses on quality and reliability in [field or service].',
        link: 'https://www.subcompany15.com', // Replace with actual link
    },
    {
        name: 'Subcompany 16',
        logo: 'link-to-logo-16.png',
        description: 'Subcompany 16 is known for its excellence in [field or service].',
        link: 'https://www.subcompany16.com', // Replace with actual link
    },
    {
        name: 'Subcompany 17',
        logo: 'link-to-logo-17.png',
        description: 'Subcompany 17 specializes in [field or service] with a strong commitment to innovation.',
        link: 'https://www.subcompany17.com', // Replace with actual link
    },
    {
        name: 'Subcompany 18',
        logo: 'link-to-logo-18.png',
        description: 'Subcompany 18 is dedicated to providing quality service in [field or service].',
        link: 'https://www.subcompany18.com', // Replace with actual link
    },
    {
        name: 'Subcompany 19',
        logo: 'link-to-logo-19.png',
        description: 'Subcompany 19 delivers exceptional results in [field or service].',
        link: 'https://www.subcompany19.com', // Replace with actual link
    },
    {
        name: 'Subcompany 20',
        logo: 'link-to-logo-20.png',
        description: 'Subcompany 20 focuses on customer satisfaction and innovative solutions.',
        link: 'https://www.subcompany20.com', // Replace with actual link
    },
    {
        name: 'Subcompany 21',
        logo: 'link-to-logo-21.png',
        description: 'Subcompany 21 is committed to excellence in [field or service].',
        link: 'https://www.subcompany21.com', // Replace with actual link
    },
    {
        name: 'Subcompany 22',
        logo: 'link-to-logo-22.png',
        description: 'Subcompany 22 specializes in [field or service] with a focus on quality.',
        link: 'https://www.subcompany22.com', // Replace with actual link
    },
    {
        name: 'Subcompany 23',
        logo: 'link-to-logo-23.png',
        description: 'Subcompany 23 is known for its expertise in [field or service].',
        link: 'https://www.subcompany23.com', // Replace with actual link
    },
];


const SubCompanies = () => {
  return (
    <section className="subcompanies">
      <h2>Our Subcompanies</h2>
      <div className="subcompanies-grid">
        {subCompanies.map((subcompany, index) => (
          <div className="subcompany-card" key={index}>
            <a href={subcompany.link} target="_blank" rel="noopener noreferrer">
              <img src={subcompany.logo} alt={`${subcompany.name} logo`} />
              <h3>{subcompany.name}</h3>
              <p>{subcompany.description}</p>
            </a>
          </div>
        ))}
      </div>
    </section>
  );
};

export default SubCompanies;
