export default class Choose
{
    constructor()
    {

    }
    language(texts , chosen)
    {
        return texts[chosen];
    }
    choosing(parentNodeID , chosen , att)
    {
        let elements = document.getElementById(parentNodeID).childNodes;
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index];
            element.classList.remove(att);
        }
        elements[chosen].classList.add(att);
        return chosen;
    }
    choosing2(classNameItems ,chosen , att)
    {
        
        let elements = document.getElementsByClassName(classNameItems);
        if (elements.length === 0) {
            return
        }
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index];
            element.classList.remove(att);
        }
        elements[chosen].classList.add(att);
        return chosen;
    }
}