// src/LatestNews.js

import React, { useEffect, useState } from 'react';
import './lastestnewsStyle.css'; // Import CSS for styling
import { FaNewspaper } from 'react-icons/fa'; // Importing an icon from react-icons
import db from '../../data/db';
import { Link } from 'react-router-dom';

const LatestNews = () => {
  const [newsArticles, setNewsList] = useState([]);
  useEffect(() => {
    const fetchNews = async () => {
      const allNews = await db.news.toArray();  // Fetch all news from IndexedDB
      setNewsList(allNews);
    };
    fetchNews();
  }, []);

  return (
    <div className="latest-news-container">
      <h2>Latest News</h2>
      <div className="news-grid">
        {newsArticles.slice(newsArticles.length - 4, newsArticles.length).map((article) => (
          <div key={article.id} className="news-item">
            <div className="icon-container">
              <FaNewspaper className="news-icon" />
            </div>
            <img src={article.photo} alt={article.title} className="news-image" /> {/* Displaying image */}
            <h3>{article.title}</h3>
            <p className="news-date">{article.date}</p>
            <p>{article.summary}</p>
            <Link
              to={`/news/${article.id}`}
              className="continue-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              Continue Reading
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LatestNews;
