// src/db.js
import Dexie from 'dexie';

// Create the database
const db = new Dexie('NewsBlogDatabase');

// Define the schema for the database
db.version(1).stores({
  news: '++id, title, date, summary, photo, text',  // Auto-incrementing ID for news
  blogs: '++id, title, description, image, text'    // Auto-incrementing ID for blogs
});

// Export the database for use in other parts of the application
export default db;
