import React from 'react';
import './PrivacyAndPolicy.css'; // Make sure to create this CSS file for styling

const PrivacyAndPolicy = () => {
    return (
        <div className="privacy-policy-container_pp">
            <h1 className="privacy-policy-title_pp">Privacy Policy</h1>
            <p className="privacy-policy-intro_pp">
                We are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data.
            </p>

            <section className="privacy-section_pp">
                <h2 className="privacy-title_pp">Privacy</h2>
                <p>
                    Our Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website. 
                    Please read it carefully to understand our views and practices regarding your personal data.
                </p>
            </section>

            <section className="data-protection-section_pp">
                <h2 className="data-protection-title_pp">Data Protection</h2>
                <p>
                    We implement various security measures to ensure the safety of your personal information. 
                    These measures include encryption, firewalls, and secure server hosting.
                </p>
                <p>
                    We also limit access to your personal information to those employees, agents, contractors, and other third parties who have a business need to know.
                </p>
            </section>

            <section className="user-rights-section_pp">
                <h2 className="user-rights-title_pp">Your Rights</h2>
                <p>
                    You have the right to access, correct, or delete your personal information at any time. 
                    You may also withdraw your consent for us to process your data.
                </p>
            </section>

            <section className="contact-us-section_pp">
                <h2 className="contact-us-title_pp">Contact Us</h2>
                <p>
                    If you have any questions or concerns regarding our Privacy Policy or data protection practices, please contact us at support@example.com.
                </p>
            </section>
        </div>
    );
};

export default PrivacyAndPolicy;
