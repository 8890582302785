// src/BlogDetail.jsx
import React,{useState , useEffect} from 'react';
import { useParams, Link } from 'react-router-dom';
import './BlogDetail.css';
import db from '../../data/db';

const BlogDetail = () => {
  const [blogs, setblogs] = useState([]);
  useEffect(() => {
    const fetchNews = async () => {
      const allNews = await db.blogs.toArray();  // Fetch all news from IndexedDB
      setblogs(allNews);
    };
    fetchNews();
  }, []);
  const { id } = useParams(); // Get the blog ID from the URL
  const blog = blogs.find((b) => b.id === parseInt(id));

  if (!blog) {
    return <div className="blog-detail-not-found_bd">Blog post not found.</div>;
  }

  return (
    <div className="blog-detail-wrapper_bd">
      <h1 className="blog-detail-title_bd">{blog.title}</h1>
      <p className="blog-detail-description_bd">{blog.description}</p>
      <img src={blog.image} alt={blog.title} className="blog-detail-image_bd" />
      <div className="blog-detail-content_bd">
        <p className="blog-detail-text_bd">{blog.text}</p>
      </div>
      <Link to="/blogs" className="blog-detail-back_button_bd">Back to Blogs</Link>
    </div>
  );
};

export default BlogDetail;
