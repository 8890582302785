// src/LogoDisplay.js

import React, { useState } from 'react';
import './logodisplayStyle.css'; // Import CSS for styling
import { FaGlobe } from 'react-icons/fa'; // Importing globe icony

const logos = [
  { id: 1, src: 'https://via.placeholder.com/100x100?text=Logo+1', name: 'Company 1', description: 'Innovative solutions for modern problems.', website: 'https://company1.com' },
  { id: 2, src: 'https://via.placeholder.com/100x100?text=Logo+2', name: 'Company 2', description: 'Leading the way in technology and design.', website: 'https://company2.com' },
  { id: 3, src: 'https://via.placeholder.com/100x100?text=Logo+3', name: 'Company 3', description: 'Delivering excellence and quality service.', website: 'https://company3.com' },
  { id: 4, src: 'https://via.placeholder.com/100x100?text=Logo+4', name: 'Company 4', description: 'Creating value through innovation.', website: 'https://company4.com' },
  { id: 5, src: 'https://via.placeholder.com/100x100?text=Logo+5', name: 'Company 5', description: 'Dedicated to customer satisfaction.', website: 'https://company5.com' },
  { id: 6, src: 'https://via.placeholder.com/100x100?text=Logo+6', name: 'Company 6', description: 'Your partner in sustainable growth.', website: 'https://company6.com' },
  { id: 7, src: 'https://via.placeholder.com/100x100?text=Logo+7', name: 'Company 7', description: 'Building the future with technology.', website: 'https://company7.com' },
  { id: 8, src: 'https://via.placeholder.com/100x100?text=Logo+8', name: 'Company 8', description: 'Transforming industries with innovation.', website: 'https://company8.com' },
  { id: 9, src: 'https://via.placeholder.com/100x100?text=Logo+9', name: 'Company 9', description: 'Empowering communities through technology.', website: 'https://company9.com' },
  { id: 10, src: 'https://via.placeholder.com/100x100?text=Logo+10', name: 'Company 10', description: 'Excellence in service and quality.', website: 'https://company10.com' },
  { id: 11, src: 'https://via.placeholder.com/100x100?text=Logo+11', name: 'Company 11', description: 'Innovative solutions for a better world.', website: 'https://company11.com' },
  { id: 12, src: 'https://via.placeholder.com/100x100?text=Logo+12', name: 'Company 12', description: 'Crafting experiences with technology.', website: 'https://company12.com' },
  { id: 13, src: 'https://via.placeholder.com/100x100?text=Logo+13', name: 'Company 13', description: 'Passionate about delivering quality.', website: 'https://company13.com' },
  { id: 14, src: 'https://via.placeholder.com/100x100?text=Logo+14', name: 'Company 14', description: 'Your vision, our commitment.', website: 'https://company14.com' },
  { id: 15, src: 'https://via.placeholder.com/100x100?text=Logo+15', name: 'Company 15', description: 'Leading the charge in innovation.', website: 'https://company15.com' },
  { id: 16, src: 'https://via.placeholder.com/100x100?text=Logo+16', name: 'Company 16', description: 'Inspiring change through technology.', website: 'https://company16.com' },
  { id: 17, src: 'https://via.placeholder.com/100x100?text=Logo+17', name: 'Company 17', description: 'Engineering a sustainable future.', website: 'https://company17.com' },
  { id: 18, src: 'https://via.placeholder.com/100x100?text=Logo+18', name: 'Company 18', description: 'Creating opportunities for success.', website: 'https://company18.com' },
  { id: 19, src: 'https://via.placeholder.com/100x100?text=Logo+19', name: 'Company 19', description: 'Quality products, exceptional service.', website: 'https://company19.com' },
  { id: 20, src: 'https://via.placeholder.com/100x100?text=Logo+20', name: 'Company 20', description: 'Commitment to excellence in every aspect.', website: 'https://company20.com' },
  { id: 21, src: 'https://via.placeholder.com/100x100?text=Logo+21', name: 'Company 21', description: 'Innovating today for a better tomorrow.', website: 'https://company21.com' },
  { id: 22, src: 'https://via.placeholder.com/100x100?text=Logo+22', name: 'Company 22', description: 'Solutions for a changing world.', website: 'https://company22.com' },
  { id: 23, src: 'https://via.placeholder.com/100x100?text=Logo+23', name: 'Company 23', description: 'Striving for excellence and innovation.', website: 'https://company23.com' },
];

const LogoDisplay = () => {
  const [selectedLogo, setSelectedLogo] = useState(null); // State to hold the selected logo

  const handleLogoClick = (logo) => {
    setSelectedLogo(logo); // Set the selected logo
  };

  return (
    <div className="logo-display-container">
      <h1 className="header-title">Company Logos</h1>
      <div className="logo-grid">
        {logos.map((logo) => (
          <div
            key={logo.id}
            className={`logo-item ${selectedLogo && selectedLogo.id !== logo.id ? 'blur' : ''}`}
            onClick={() => handleLogoClick(logo)} // Click handler for logos
          >
            <img src={logo.src} alt={logo.name} className="logo-image" />
          </div>
        ))}
      </div>
      <div className="logo-details">
        {selectedLogo ? (
          <div className="company-info">
            <img src={selectedLogo.src} alt={selectedLogo.name} className="company-logo" />
            <h3>{selectedLogo.name}</h3>
            <p className="website-info">
              <FaGlobe className="globe-icon" />
              <a href={selectedLogo.website} target="_blank" rel="noopener noreferrer">
                {selectedLogo.website}
              </a>
            </p>
            <p>{selectedLogo.description}</p>
          </div>
        ) : (
          <p className="select-prompt">Select a logo to see details</p>
        )}
      </div>
    </div>
  );
};

export default LogoDisplay;
