// src/SubCompanies.jsx

import React from 'react';
import './LeaderShip.css'; // Create this CSS file for styling

const leadershipTeam = [
    {
        name: 'John Doe',
        title: 'CEO',
        image: '/path/to/image1.jpg', // Replace with actual image paths
    },
    {
        name: 'Jane Smith',
        title: 'CTO',
        image: '/path/to/image2.jpg',
    },
    {
        name: 'Mike Johnson',
        title: 'COO',
        image: '/path/to/image3.jpg',
    },
    // Add more leadership members as needed
];


const LeaderShipBox = () => {
    return (
        <section className="leadership">
            <h2>Meet Our Leadership</h2>
            <div className="team-members">
                {leadershipTeam.map((member, index) => (
                    <div className="team-member" key={index}>
                        <img src={member.image} alt={member.name} />
                        <h3>{member.name}</h3>
                        <p>{member.title}</p>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default LeaderShipBox;
