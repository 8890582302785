// src/BlogItem.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import './BlogItem.css'; // Ensure your CSS is linked

const BlogItem = ({ blog }) => {
  return (
    <div className="blog-item-container_bi">
      <img src={blog.image} alt={blog.title} className="blog-item-image_bi" />
      <h3 className="blog-item-title_bi">{blog.title}</h3>
      <p className="blog-item-description_bi">{blog.description}</p>
      <Link to={`/blogs/${blog.id}`} className="read-more-button_bi">Read More</Link>
    </div>
  );
};

export default BlogItem;
