import React, { Component } from "react";
import "./errors.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const InProgress = (props) => {
  return (
    <>
      <div className="error-box fixed-top inprogress">
        <div className="row">
          <div className="col-3"></div>
          <div className="col-9 padding-1">
            <h4 className="error-title">{props.title}</h4>
          </div>
        </div>
      </div>
    </>
  );
};

export default InProgress;
